// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NavBar from './Pages/Navbar';
import About from './Pages/About';
import Work from './Pages/Work';
import Classes from './Pages/Classes';
import Projects from './Pages/Projects';
import './Pages/App.css';
import logo from './Pages/logo.png'

function App() {
  return (
<Router>
      <div className="App">
        <header className="App-header">
        <a href='/'>
                    <img src={logo} alt='Addison Fenrick' className='logo' />
                </a>
          <NavBar />
        </header>
        <main>
          <Routes>
            <Route path="/" element={<About />} />
            <Route path="/work" element={<Work />} />
            <Route path="/classes" element={<Classes />} />
            <Route path="/projects" element={<Projects />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
