import NavBar from './Navbar';
import logo from './logo.png';

export default function Projects() {
    return (
        <div className="App">

        <body className="Body">
        </body>
      </div>
    );
  }
  