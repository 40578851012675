import logo from './logo.png';
import NavBar from './Navbar.js';
import senior_picture from './senior_picture.jpg'
export default function About() {
    return (
        <div className="App">
      <body className="Body">
                {/* <h1 className="Text_Heading">About Me</h1>   / */}
                <div className="container">
                    <div className='item'>
                        <img src={senior_picture} className="Personal_Photo" />
                    </div>
                    <div className='item'>
                        <h4>
                            I am currently pursuing a degree in Cybersecurity and Computer Science at the University of Wisconsin – Platteville. Simultaneously, I hold a position as a Part Time Student Software Engineer at John Deere. In my free time, I actively develop mobile applications for the App Store.
                        </h4>
                        <h4>
                            I take great pride in being a member of the 22-time National Champion Rock Aqua Water Ski Show Team. Within the team, my role in sound and production for the show is crucial. I have contributed to seven of the team’s national titles and twice within the sound crew.
                        </h4>
                        <h4>
                            My passion for robotics and technology dates back to my involvement in FIRST Lego League during middle school. In high school, I dedicated four years to our FIRST Robotics Competition, cross country, and track teams. Additionally, I actively participated in the Science National Honor Society, National Honor Society, Link Crew, and the Computer Science club.
                        </h4>
                        <h4>
                            During my high school years, I volunteered at my middle school, assisting with athletic events. Since graduating, I have maintained my commitment to volunteering, serving as a referee for the 2023 FIRST Lego League Competition. I also returned to my middle school to assist eighth graders in signing up for high school classes. Furthermore, I continue to contribute to my church by assisting with their live stream and technology needs.
                        </h4>
                        <h4>
                            At the University of Wisconsin–Platteville, I am a proud member of the Ultimate Frisbee club team and the Cybersecurity club, expanding my involvement in extracurricular activities.
                        </h4>
                        <h4>
                            My journey in Cybersecurity and Computer Science, along with my diverse involvements, has shaped my passion for technology and community service. From contributing to the success of the Rock Aqua Water Ski Show Team to volunteering in various capacities, I am committed to making a positive impact. As I continue my studies at the University of Wisconsin – Platteville, I look forward to further opportunities for growth and collaboration in both my academic and extracurricular pursuits.
                        </h4>
                    </div>
                </div>

            </body>
        </div>
    );
}

